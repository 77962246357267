<template>
  <div>
    <h1 v-text="title"></h1>
    <b-table
      v-if="wizardItems.length"
      :items="wizardItems"
      striped
      stacked="sm"
      :fields="fields"
    >
      <template #cell(title)="{ item }">
        <strong v-html="item.title"></strong>
      </template>
      <template #cell(answer)="{ item }">
        <template v-if="isBooleanAnswer(item)">
          <b-icon-check2-square
            v-if="item.answer === 'true' || item.answer === true"
          ></b-icon-check2-square>
          <b-icon-square v-else></b-icon-square>
        </template>
        <template v-else-if="isSelectAnswer(item)">
          <strong v-if="item.multiple" v-html="item.answer.join(', ')"></strong>
          <strong v-else v-html="item.answer"></strong>
        </template>
        <template v-else-if="isFileAnswer(item)">
          <FilesPreview v-if="item.answer" :value="item.answer" />
          <b-icon-square v-else></b-icon-square>
        </template>
        <template v-else-if="isSignatureAnswer(item)">
          <b-icon-check2-square v-if="item.answer"></b-icon-check2-square>
          <b-icon-square v-else></b-icon-square>
        </template>
        <strong v-else v-text="item.answer"></strong>
      </template>
    </b-table>
    <slot></slot>
  </div>
</template>

<script>
import FilesPreview from "./FilesPreview";

export default {
  components: {
    FilesPreview,
  },
  name: "WizardSummary",
  data() {
    let title = this.$t("summary.title");
    if (this.$store.getters.order && this.$store.getters.order.isPrecheck) {
      title = this.$t("summary.partner-precheck.title");
    }
    return {
      title: title,
      fields: [
        { key: "title", label: this.$t("title") },
        { key: "answer", label: this.$t("answer") },
      ],
    };
  },
  computed: {
    wizardItems() {
      return this.$store.getters.wizardItemsForSummary;
    },
  },
  methods: {
    isBooleanAnswer(item) {
      return (
        item.answer === "true" ||
        item.answer === "false" ||
        item.answer === true ||
        item.answer === false
      );
    },
    isSelectAnswer(item) {
      return item.type === "select";
    },
    isFileAnswer(item) {
      return item.type === "file";
    },
    isSignatureAnswer(item) {
      return item.type === "signature";
    },
  },
};
</script>
