<template>
  <label
    class="zone-container"
    :class="{ active: dragOver, disabled }"
    @drop.prevent="onDrop"
    @dragleave.prevent="onDragLeave"
    @dragover.prevent="onDragOver"
  >
    <input
      type="file"
      style="display: none"
      :style="disabled && { pointerEvents: 'none' }"
      @change="onChangeInput"
      value=""
      :multiple="multiple"
      :disabled="disabled"
      :accept="accept"
    />
    <div v-if="internalFiles.length === 0" class="placeholder-container">
      <template v-if="!dragOver">
        <i class="upload-icon material-symbols-outlined">upload</i>
        <span class="primary text">{{
          $t("file-upload.drop-instruction")
        }}</span>
        <span class="secondary text">
          {{ $t("file-upload.upload-instruction") }}
        </span>
      </template>
      <template v-else>
        <i class="drop-icon material-symbols-outlined">arrow_downward_alt</i>
        <span class="primary text">>{{ $t("file-upload.drop-here") }}</span>
        <span class="secondary text">&nbsp;</span>
      </template>
    </div>
    <template v-else>
      <div class="zone-file" v-for="(file, i) in internalFiles" :key="i">
        <div @click.stop.prevent v-if="!file.loading" class="info-overlay">
          <label class="name" :title="file.name">{{ file.name }}</label>
          <i
            v-if="!disabled"
            class="circle close-btn material-symbols-outlined"
            @click.stop.prevent="deleteFile(file.id)"
          >
            close
          </i>
          <i
            v-if="file.resampled"
            class="circle resampled material-symbols-outlined"
            v-b-tooltip.hover
            :title="$t('file-upload.resampled')"
          >
            exclamation
          </i>
        </div>
        <div v-else @click.stop.prevent class="loading-overlay">
          <b-spinner variant="light"></b-spinner>
        </div>
        <img
          v-if="file.preview"
          :src="buildPreviewByType(file.preview, file.mimetype)"
          :alt="file.name"
        />
        <i v-else class="material-symbols-outlined">
          {{ getIconByType(file.mimetype) }}
        </i>
      </div>
      <div class="upload-hint" v-if="!disabled">
        <i class="material-symbols-outlined">{{
          this.multiple ? "add" : "reset_image"
        }}</i>
        <span>{{
          this.multiple ? $t("file-upload.add-more") : $t("file-upload.replace")
        }}</span>
      </div>
    </template>
  </label>
</template>

<script>
import axios from "axios";

function getDocument(pdfData) {
  // Using import statement in this way allows Webpack
  // to treat pdf.js as an async dependency so we can
  // avoid adding it to one of the main bundles
  return import(
    /* webpackChunkName: 'pdfjs-dist' */
    "pdfjs-dist/webpack"
  ).then((pdfjs) => pdfjs.getDocument(pdfData));
}

export default {
  props: {
    value: Array,
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: Infinity,
    },
    maxFileSizeMb: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "",
    },
    uploadUrl: {
      type: String,
    },
    deleteUrl: {
      type: String,
    },
  },
  data() {
    return {
      internalFiles: [],
      dragOver: false,
    };
  },
  watch: {
    internalFiles: {
      handler(newFiles) {
        this.$emit("input", newFiles);
      },
      deep: true,
    },
    value: {
      handler(newVal) {
        this.internalFiles = newVal || [];
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    isMaxFilesExceeded() {
      return this.internalFiles.length >= this.maxFiles;
    },
  },
  methods: {
    async onChangeInput(event) {
      if (event.target.files.length === 0) return;

      await this.handleFileUpload(Array.from(event.target.files));

      event.target.value = "";
    },
    async onDrop(event) {
      this.onDragLeave();

      if (event.dataTransfer.files.length === 0) return;

      await this.handleFileUpload(Array.from(event.dataTransfer.files));
    },
    async handleFileUpload(files) {
      for (const file of files) {
        if (this.isMaxFilesExceeded || !file.type.includes("image/")) continue;
        this.addFile(file);
      }
    },
    async addFile(file) {
      let fileToAdd = file;
      const fileWithInfo = {
        name: fileToAdd.name,
        key: null,
        preview: null,
        mimetype: fileToAdd.type,
        loading: true,
        resampled: false,
        id: Math.random().toString(36).substring(2, 11),
      };

      if (this.multiple) {
        this.internalFiles.push(fileWithInfo);
      } else {
        Promise.all(this.internalFiles.map((file) => this.deleteFile(file.id)));

        this.internalFiles = [fileWithInfo];
      }

      if (this.isMaxFileSizeExceeded(file)) {
        let resampleFunction;

        if (file.type.startsWith("image/")) {
          resampleFunction = this.resampleImage;
        } else if (file.type === "application/pdf") {
          resampleFunction = this.resamplePDF;
        }

        try {
          if (resampleFunction) {
            fileToAdd = await resampleFunction.call(this, fileToAdd);
            if (this.isMaxFileSizeExceeded(fileToAdd)) {
              throw new Error(
                this.$t("file-upload.errors.resampled-too-large")
              );
            }

            fileWithInfo.name = fileToAdd.name;
            fileWithInfo.mimetype = fileToAdd.type;
            fileWithInfo.resampled = true;
          } else {
            throw new Error(
              this.$t("file-upload.errors.too-large", {
                maxSize: this.maxFileSizeMb,
              })
            );
          }
        } catch (error) {
          this.$bvToast.toast(error.message, {
            title: this.$t("error"),
            variant: "danger",
          });
          this.deleteFile(fileWithInfo.id);
          return;
        }
      }

      const formData = new FormData();
      formData.append("file", fileToAdd);

      try {
        const { data } = await axios.post(this.uploadUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        fileWithInfo.key = data.key;
        if (data.preview) {
          fileWithInfo.preview = data.preview;
        }
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: this.$t("error"),
          variant: "danger",
        });
        this.deleteFile(fileWithInfo.id);
      } finally {
        fileWithInfo.loading = false;
      }
    },
    async deleteFile(id) {
      const file = this.internalFiles.find((file) => file.id && file.id === id);
      if (!file || !id) return;

      if (file.key) {
        axios.delete(this.deleteUrl, { data: { key: file.key } });
      }

      this.internalFiles = this.internalFiles.filter((file) => file.id !== id);
    },
    isMaxFileSizeExceeded(file) {
      const maxSizeInBytes = this.maxFileSizeMb * 1024 * 1024;
      return file.size > maxSizeInBytes;
    },
    buildPreviewByType(data, mimetype) {
      return `data:${mimetype};base64,${data}`;
    },
    onDragLeave() {
      if (!this.disabled && this.dragOver) this.dragOver = false;
    },
    onDragOver() {
      if (!this.disabled && !this.dragOver) this.dragOver = true;
    },
    getIconByType(mimetype) {
      if (mimetype === "application/pdf") {
        return "picture_as_pdf";
      } else if (mimetype.startsWith("image/")) {
        return "collections";
      }
      return "description";
    },
    async resampleImage(file) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const objectURL = URL.createObjectURL(file);
        img.src = objectURL;

        img.onload = () => {
          const elem = document.createElement("canvas");
          const aspect = img.width / img.height;
          const scale = Math.min(1500 / img.width, 1500 / img.height, 0.8);
          let width, height;

          if (aspect > 1) {
            width = img.width * scale;
            height = width / aspect;
          } else {
            height = img.height * scale;
            width = height * aspect;
          }

          elem.width = width;
          elem.height = height;

          const ctx = elem.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          ctx.canvas.toBlob(
            (blob) => {
              const fileNameParts = file.name.split(".");
              const extension = fileNameParts.pop();
              const baseName = fileNameParts.join(".");
              const resampledFileName = `${baseName}_resampled.${extension}`;
              const resampledFile = new File([blob], resampledFileName, {
                type: "image/jpeg",
              });
              resolve(resampledFile);
              URL.revokeObjectURL(objectURL);
            },
            "image/jpeg",
            0.8
          );
        };

        img.onerror = (error) => {
          reject(error);
          URL.revokeObjectURL(objectURL);
        };
      });
    },
    async resamplePDF(file) {
      const jsPDF = await import("jspdf").then((module) => module.jsPDF);
      return new Promise((resolve, reject) => {
        const objectURL = URL.createObjectURL(file);
        const loadingTask = getDocument(objectURL);

        loadingTask
          .then((pdfDoc) => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const resampledPDF = new jsPDF();
            let currentPage = 1;

            const renderNextPage = () => {
              if (currentPage > pdfDoc.numPages) {
                const pdfBlob = resampledPDF.output("blob");

                const originalName = file.name.replace(/\.[^/.]+$/, "");
                const resampledFileName = `${originalName}_resampled.pdf`;

                const outputFile = new File([pdfBlob], resampledFileName, {
                  type: "application/pdf",
                });

                resolve(outputFile);
                URL.revokeObjectURL(objectURL);
                return;
              }

              pdfDoc.getPage(currentPage).then((page) => {
                const originalViewport = page.getViewport({ scale: 1 });
                const scale = Math.min(
                  1500 / originalViewport.width,
                  1500 / originalViewport.height,
                  0.8
                );
                const viewport = page.getViewport({ scale });

                canvas.width = viewport.width;
                canvas.height = viewport.height;

                const renderContext = {
                  canvasContext: ctx,
                  viewport,
                };

                page.render(renderContext).promise.then(() => {
                  canvas.toBlob(
                    (blob) => {
                      const url = URL.createObjectURL(blob);

                      if (currentPage > 1) {
                        resampledPDF.addPage();
                      }

                      const widthInMm = (originalViewport.width / 72) * 25.4;
                      const heightInMm = (originalViewport.height / 72) * 25.4;

                      resampledPDF.addImage(
                        url,
                        "JPEG",
                        0,
                        0,
                        widthInMm,
                        heightInMm
                      );

                      URL.revokeObjectURL(url);

                      currentPage++;
                      renderNextPage();
                    },
                    "image/jpeg",
                    0.8
                  );
                });
              });
            };

            renderNextPage();
          })
          .catch((error) => {
            reject(error);
            URL.revokeObjectURL(objectURL);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes/default.scss";

.zone-container {
  width: 100%;
  max-width: none;
  min-height: 8rem;
  border: 0.2rem dashed #bdc3c9;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  transition: 0.2s;
  margin: 0;
  padding: 0.5rem;

  &.disabled {
    cursor: auto;
    background-color: lighten(#bdc3c9, 18%);
  }

  &.active {
    border-color: $primary;
  }

  .zone-file {
    padding: 0.7rem;
    position: relative;
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    > img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    > i {
      font-size: 6.5rem;
      color: $primary;
    }

    .info-overlay {
      opacity: 0;
      position: absolute;
      background-color: rgba($primary, 0.3);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 0.25rem;
      z-index: 1;

      .circle {
        display: inline-block;
        font-size: 1rem;
        padding: 0.3rem;
        font-weight: bold;
        border-radius: 50rem;
        user-select: none;
        position: absolute;
        top: 0;

        &.resampled {
          background-color: #f98747;
          color: white;
          left: 0;
        }

        &.close-btn {
          background-color: #ff5252;
          color: white;
          right: 0;
        }
      }

      .name {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 0.8rem;
        margin: 0 auto 0.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: fit-content;
        max-width: 80%;
        font-weight: 500;
        display: inline-block;
        background-color: white;
        padding: 0.1rem 0.4rem;
        border-radius: 0.25rem;
      }
    }

    .loading-overlay {
      position: absolute;
      background-color: rgba(#000, 0.2);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 0.25rem;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      .info-overlay {
        transition: 0.1s opacity;
        opacity: 1;
      }
    }
  }

  .upload-hint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    padding: 1rem;
    color: lighten(#000, 60%);
    pointer-events: none;
    user-select: none;

    i {
      font-size: 2.3rem;
    }

    span {
      margin-top: 0.2rem;
      font-size: 0.8rem;
    }
  }

  .placeholder-container {
    margin: 0 auto;
    align-self: center;
    color: #bdc3c9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    pointer-events: none;
    user-select: none;

    .text {
      text-align: center;

      &.primary {
        font-size: 1.2rem;
        font-weight: 500;
      }

      &.secondary {
        font-size: 1rem;
        color: rgba(#bdc3c9, 0.8);
      }
    }

    .upload-icon {
      font-size: 4rem;
    }

    .drop-icon {
      color: $primary;
      font-size: 4rem;
      animation: arrow 0.8s infinite alternate;
    }

    @keyframes arrow {
      from {
        transform: translateY(0%);
      }

      to {
        transform: translateY(8%);
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .zone-container {
    .placeholder-container {
      .text {
        &.primary {
          font-size: 1rem;
        }

        &.secondary {
          font-size: 0.9rem;
        }
      }

      .upload-icon {
        font-size: 3.2rem;
      }

      .drop-icon {
        font-size: 3.2rem;
      }
    }
  }
}
</style>
